<script lang="ts" setup>
import { useAppBreakpoints } from '@peng_kai/theme/vue'

const visible = ref(false)
const ltTabletl = toRef(useAppBreakpoints(), 'ltTabletl')
</script>

<template>
  <template v-if="ltTabletl">
    <span @click="visible = true" data-test-id="mojt">
      <slot name="default" />
    </span>
    <AModal
      v-model:open="visible"
      wrapClassName="ant-cover__basic-modal"
      :footer="null"
      width="fit-content"
    >
      <div class="example-content">
        <slot name="content" />
      </div>
    </AModal>
  </template>
  <template v-else>
    <APopover placement="left">
      <template #content><slot name="content" /></template>
      <template #default><slot name="default" /></template>
    </APopover>
  </template>
</template>

<style lang="scss" scoped>
.example-content :slotted(img) {
  @media bp-lt-tabletl {
    height: 70vh;
  }
}

.example-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}
</style>
