<script lang="ts" setup>
import { useSwapMachine } from '../machine/useSwapMachine'

const { machine, states } = useSwapMachine()!
</script>

<template>
  <div class="pay-success">
    <AModal
      wrapClassName="ant-cover__basic-modal"
      :open="states.successful"
      :maskClosable="false"
      :closable="false"
      :footer="null"
      @cancel="machine.send('OK')"
    >
      <OrderResult
        icon="success"
        :title="$t('KoObKozEGyhVU7yRWCFX3')"
        @onClick="machine.send('OK')"
      />
    </AModal>
  </div>
</template>

<style lang="scss" scoped>
.popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 100px;
    height: 100px;
  }

  & > .text {
    font-size: 20px;
  }

  & > .confirm-button {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    border-radius: 6px;
    background-color: #2a47ab;
    color: white;
  }

  & > * {
    margin: 10px 0;
  }
}
</style>
