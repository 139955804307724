<script setup lang="ts">
import { debounce } from 'lodash-es'

//
import { useSwapMachine } from '../machine/useSwapMachine'
import PaySuccessPopup from './PaySuccess.vue'

const { machine, states } = useSwapMachine()!
const mctx = computed(() => machine.state.value.context)

const onClick = {
  PAY: debounce(
    () => {
      machine.send('PAY')
    },
    800,
    { leading: true, trailing: false },
  ),
}
</script>

<template>
  <div class="confirm-pay-info">
    <AModal
      wrapClassName="ant-cover__basic-modal"
      :open="states.confirming || states.requesting"
      :footer="null"
      :maskClosable="true"
      :keyboard="true"
      :title="$t('LS1g98LxrV2WvgoLcboKu')"
      :closable="states.canClose"
      @cancel="machine.send('CLOSE')"
    >
      <div class="popup">
        <div class="pay-address">
          <span>{{ $t("K3YbjRNYwB37wyxkpldjL") }}</span>
          <span class="text-white">{{
            desensitization(mctx.payAddress, 10, -10)
          }}</span>
        </div>
        <div class="receiver-address">
          <span>{{ $t("jiir2eTmqaOvbAf1uGj06") }}</span>
          <span class="text-white">{{
            desensitization(mctx.receiveAddress || mctx.payAddress, 10, -10)
          }}</span>
        </div>
        <div class="exchange-info">
          <span>{{ $t("_X-tn_iR5wA0xAcbpbKCx") }}</span>
          <span style="margin: 0 8px">
            {{ formatAmount(mctx.payAmount, 3) }}
          </span>
          <span>{{ mctx.paySymbol }}</span>
          <span style="margin-left: 8px">{{
            $t("wZutznikQLic6i7sOz0J5")
          }}</span>
          <span style="margin: 0 8px">
            {{ formatAmount(mctx.exchangeAmount, 3) }}
          </span>
          <span>{{ mctx.exchangeSymbol }}</span>
        </div>
        <div class="pay-info">
          <span class="label">{{ $t("rVRzzZPq-cNxU2MMv_7aZ") }}</span>
          <div class="value">
            <span class="text-[#27c1a5] at-tabletl:text-18px">{{ mctx.payAmount }}</span>
            <span class="text-white ml-3">{{ mctx.paySymbol }}</span>
          </div>
        </div>

        <div class="hint">
          <span>{{ $t("KFKd_l84bV_Uxu6I63Emh") }} {{ mctx.exchangeSymbol }}</span>
        </div>

        <div class="action">
          <AButton
            :disabled="!states.canClose"
            class="cancel-btn"
            data-test-id="4hbl"
            @click="machine.send('CLOSE')"
          >
            {{ $t("3Sy5Ym3mSx7yphQbJCdPo") }}
          </AButton>
          <AButton
            :loading="states.requesting"
            class="comfirm-btn ml-10px"
            data-test-id="quyx"
            @click="onClick.PAY"
          >
            {{ $t("eZ4sG6S0vn3U5mdh1etvj") }}
          </AButton>
        </div>
      </div>
    </AModal>
    <PaySuccessPopup ref="PaySuccessPopupRef" />
  </div>
</template>

<style scoped lang="scss">
.popup {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10px;

  & > * {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-top: 12px;
    background-color: #13192e;
    border-radius: 10px;

    @media bp-lt-tablet {
      margin-top: 5px;
      font-size: 15px;
    }
  }

  & > .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: white;

    @media bp-lt-tablet {
      padding: 0;
      margin-bottom: 15px;
    }
  }

  .pay-address,
  & > .receiver-address {
    @media bp-lt-tablet {
      flex-direction: column;
    }
  }

  & > .exchange-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  & > .hint {
    display: flex;
    justify-content: flex-start;
    padding: 15px 0 0;
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--secondary-text);
    background-color: transparent;
  }

  .action {
    padding: 0;
    background: transparent;

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 47%;
      padding: 20px 0;
      color: white;
      border-radius: 6px;

      @media bp-lt-tablet {
        padding: 25px 0;
        font-size: 16px;
      }
    }
  }
}
</style>
