import { createMachine } from 'xstate'

export { machine }
export type { IContext }

interface IContext {
  payAddress: string
  payAmount: number
  paySymbol: string
  exchangeAmount: number
  exchangeSymbol: string
  receiveAddress: string
  energySurplus : number
  trxSurplus : number
  bandwidthSurplus : number
}

const machine
  /** @xstate-layout N4IgpgJg5mDOIC5QGUDuBDADgOgJYQBswBiAYQHkA5AMQEkAlAWQG0AGAXUVEwHtZcALrh4A7LiAAeiAIwBWAOwBmbPIBsAJlatVsxQA5161aoA0IAJ6IALPPXZFATlW3186YqPrpVgL4+zaFjYAMaiAGa4AE4AtrgiUMQACgCCAJpsnEggvPxCouJSCNKqctiycrJa8lbS8rqmFoiarCpGsqpW6rJO8g4OfgEYOKEiETFxCaQAMuTIAKIZ4jmCwmJZhXIO8vZK0ppurFaK2maWCJ3S2A7qVuXqW6oO2rIDIIE4mJFgALSwAK4AI1iAiE8WIEFEYDwIgAbjwANZQz4-f5AwSLLLLPJrUAbQyXPSydSKTa6ElE06IDyXKwaYwOPbyQlM17vbDI36A4GghJgSKRHiRdkEdACMKC6Lsr6ctECDHcPgrfLrGT47CE4mkxTk9SUhB6Gl6Jm6WRWVjVBm+fxvIbs9DmCbgyHQuGIu3meXZRXYgqqjqtWQKKxmhxGgx6xSqPRXM2sJ7tBTSa6KVm2zD2x18gVCzAisUS92erGrX1FBTKNTNHT6QzGCO6bCsVxJvSWtRR1NBQUQPnfYJfUWOiEiKFxV1Q-tgUU-bt8oveksqhAeaN6clRtzuZwOKx6jTbPZGolPRRMvSqTs4WeRPsDnnELOC4Wi8UxEJ3meRHuRee5Re4ql1FXdcjWkLdel3RoEDUBx1WcKw9CPdpWwva02Wvb4AEc-j5B0wWHUdYQRKFsNw75r1-JUcUkRAmTsSptT6YlWANE4oIQ2DpD0KwnieWw43uS9sAw0jIjw3l+SfXMXwLUTzHIr85w4JYF2VADoIMMpWEY5MWOkNizg6S5Tx40MuPkOpAyE-5gmCOBYDCP4CGIcgAGlKJ9Jc9i8dUiXJBwyTkXV2KNbAN3KHjDG42Q9D8a0RB4Ht4Cyd4VL-NSaIQb4GjObLGy0Jtyl4pseOkIT8CINKqNLbx9HVElaUJbSWKNPUEOjWpEJJJQajcI0hJGMZYniKrPPU2oG16ZwPCJDod2Cs46mUPQmwMbS6j2LYhI5VFuQmUb-0ygKCRKVh9NDEkSj1WptlsRxVFPLobH6tC0wzEbMVU6iNkcFp5G0Wk6nbHjILOFcylcYlGpYs0rUGLtFJvSdBw+hV0u+6wFsQVQtGwQ8z3pc9DCEkScLE-bPvR0tKlkFRnmOM1eh3aQ9ybMKgNsCz-oNbjrL+Wz7McggDoyjZCvq4kdDcB65DaxC8bPM6gNpUy4p8IA */
  = createMachine(
    {
      id: 'Swap',
      initial: 'idle',
      states: {
        'idle': {
          on: {
            CONFIRM: {
              target: 'confirming',
              actions: {
                type: 'setInfo',
                params: {},
              },
            },
          },
        },
        'confirming': {
          tags: 'confirming',
          on: {
            PAY: {
              target: 'pre-submitting',
            },
            CLOSE: {
              target: 'idle',
            },
          },
        },
        'pre-submitting': {
          invoke: {
            src: 'preSubmit',
            id: 'pre-submit',
            onDone: [
              {
                target: 'paying',
              },
            ],
            onError: [
              {
                target: 'confirming',
                actions: ['showErrorMessage'],
              },
            ],
          },
          tags: ['requesting', 'confirming'],
        },
        'paying': {
          invoke: {
            src: 'pay',
            id: 'pay',
            onDone: [
              {
                target: 'order-creating',
              },
            ],
            onError: [
              {
                target: 'confirming',
                actions: ['showTronErrorMessage'],
              },
            ],
          },
          tags: ['requesting', 'confirming'],
        },
        'order-creating': {
          invoke: {
            src: 'createOrder',
            id: 'create-order',
            onDone: [
              {
                target: 'order-querying',
              },
            ],
            onError: [
              {
                target: 'confirming',
                actions: ['showErrorMessage'],
              },
            ],
          },
          tags: ['requesting', 'confirming'],
        },
        'order-querying': {
          invoke: {
            src: 'queryOrder',
            id: 'query-order',
            onDone: [
              {
                target: 'successful',
              },
            ],
            onError: [
              {
                target: 'confirming',
                actions: {
                  type: 'showErrorMessage',
                  params: {},
                },
              },
            ],
          },
          tags: ['requesting', 'confirming'],
        },
        'successful': {
          on: {
            OK: {
              target: 'idle',
            },
          },
        },
      },
      context: {} as unknown as IContext,
      schema: {
        events: {} as ({ type: 'CONFIRM' } & IContext) | { type: 'CLOSE' } | { type: 'PAY' } | { type: 'OK' },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      tsTypes: {} as unknown as import('./swapMachine.typegen').Typegen0,
    },
    {},
  )
