<script lang="ts">
import { useTimeoutFn } from '@vueuse/core'
import { useSwap } from '../hooks/useSwap'
import { useSwapMachineProvider } from '../machine/useSwapMachine'
import ConfirmPayInfo from './ConfirmPayInfo.vue'
import { tron } from '~/utils/tron-v2'
import { useBandwidthSurplus } from '~/composables/tron/useBandwidthSurplus'
import useHideFormHelp from '~/composables/useHideFormHelp'

function useReceiveAddress() {
  const address = ref('')
  const custom = ref(false)

  return reactive({ value: address, custom })
}
</script>

<script setup lang="ts">
const { account, refetch } = tronComp.useAccountCurrent()
const { switchCrypto, fromCrypto, toCrypto, disable, clearValue } = useSwap()!

const { isPending } = useTimeoutFn(() => {}, 1000)

function shortcutInput(val: number) {
  if (val > fromCrypto.info.balance)
    return aMessage.error(`${$t('yHxPHs8xBObNpre3t3uxL')} ${fromCrypto.info.symbol}  ${$t('smItyVQaod8JL2iUWmxQR')}  ${val}`)
  else
    fromCrypto.value = val
  swapForm.$form.value?.clearValidate()
}
const receiveAddress = useReceiveAddress()
const { machine } = useSwapMachineProvider()

const swapForm = useAntFormValidator({
  fromCrypto: {
    value: toRef(fromCrypto, 'value'),
    validator(rule, value) {
      if (typeof value !== 'number')
        return promiseError($t('ohxcq10tOgzgCUdbqRZd6'))
      if (fromCrypto.info.balance < fromCrypto.info.minValue)
        return promiseError(`${$t('1sEkMaiJkhc0FhfRldfAx')} ${fromCrypto.info.symbol} ${$t('7_3sfoam5eCfXmIxIsUhY')}`)

      if (value < fromCrypto.info.minValue)
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(`${$t('cEmfZeyxDlkTkp9W-WoV-')} ${fromCrypto.info.minValue} ${fromCrypto.info.symbol}`)

      if (value > fromCrypto.info.balance)
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(`${$t('yHxPHs8xBObNpre3t3uxL')} ${fromCrypto.info.symbol}  ${$t('smItyVQaod8JL2iUWmxQR')}  ${value}`)

      return Promise.resolve()
    },
  },
  toCrypto: {
    value: toRef(toCrypto, 'value'),
  },
  receiveAddress: {
    value: toRef(receiveAddress, 'value'),
    validator(rule, value) {
      if (value && !tron.isAddress(value))
        return Promise.reject($t('sKnanx9S2n_2cIc8xWvu1'))

      return Promise.resolve()
    },
  },
})

const { target, checking } = useHideFormHelp(swapForm.$form)

machine.service.onTransition((state) => {
  // 支付成功时清空表单
  if (state.value === 'successful') {
    clearValue()
    refetch()
  }
})

function onValidate() {
  return new Promise((resolve, reject) => {
    // 重写表单验证
    if (fromCrypto.info.balance > fromCrypto.info.minValue && (fromCrypto?.value ?? 0) < fromCrypto.info.minValue) {
      aMessage.error(`${$t('cEmfZeyxDlkTkp9W-WoV-')} ${fromCrypto.info.minValue} ${fromCrypto.info.symbol}`)
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('')
    }

    if ((fromCrypto?.value ?? 0) > fromCrypto.info.balance) {
      aMessage.error(`${$t('yHxPHs8xBObNpre3t3uxL')} ${fromCrypto.info.symbol}  ${$t('smItyVQaod8JL2iUWmxQR')}  ${fromCrypto?.value}`)
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('')
    }

    if (fromCrypto.info.symbol === toCrypto.info.symbol) {
      aMessage.error($t($t('jsrd4WsKxydk7j4HGeQz2')))
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('')
    }
    resolve(true)
  })
}

async function submit() {
  const data = await swapForm.$form.value?.validate().catch(() => {
    checking.setTrue()
  })

  await onValidate()

  if (data) {
    const { account, refetch } = tronComp.useAccountCurrent()
    // 获取到最新的账号余额，避免时长
    refetch()
    machine.send({
      type: 'CONFIRM',
      payAddress: account.address,
      payAmount: fromCrypto.value!,
      paySymbol: fromCrypto.info.symbol,
      exchangeAmount: toCrypto.value!,
      exchangeSymbol: toCrypto.info.symbol,
      receiveAddress: data?.receiveAddress || account.address,
      energySurplus: (account?.resource?.EnergyLimit ?? 0) - (account?.resource?.EnergyUsed ?? 0), // 当前剩余能量
      trxSurplus: (account?.balanceTrx ?? 0) / 1000000, // 当前剩余TRX
      bandwidthSurplus: unref(useBandwidthSurplus), // 当前剩余带宽
    })
  }
}
</script>

<template>
  <AForm v-bind="swapForm.formProps" class="antd-cover__bubble-help-form">
    <div class="dapp-pay text-[14px]">
      <template v-if="account.address">
        <div class="wallet-address">
          <i class="i-local:trx text-[20px] mr-[10px]" />
          <span>{{ account.address }}</span>
        </div>
      </template>

      <div class="from-currency">
        <div class="balance">
          <span class="label">{{ $t("_X-tn_iR5wA0xAcbpbKCx") }}</span>
          <div class="balance-info">
            <span>{{ $t("ggM-EPn4zAWeodigP9_8M") }} </span>

            <ASpin :spinning="isPending">
              <span class="balance-info-number">{{ fromCrypto.info.balance }}
              </span>
            </ASpin>
          </div>
        </div>

        <div class="input">
          <AFormItem v-bind="swapForm.formItemProps.fromCrypto">
            <AInputNumber
              v-model:value="(fromCrypto.value as number)"
              v-bind="inputMoney"
              :bordered="false"
              :controls="false"
              :placeholder="$t('Z3dE8F8H0GPEYq_juWdf5')"
              :min="0"
              :max="fromCrypto.info.balance"
              :precision="6"
              :disabled="disable"
            >
              <template #addonAfter>
                <i
                  v-if="!!fromCrypto.value"
                  class="i-ant-design:close-circle-outlined input-clear"
                  data-test-id="8yy0"
                  @click="clearValue"
                />
              </template>
            </AInputNumber>
          </AFormItem>
          <div class="select-currency">
            <ASelect v-model:value="fromCrypto.code" popupClassName="ant-cover__basic-select-dropdown" data-test-id="hYro">
              <template #suffixIcon>
                <i class="i-ant-design:down-outlined select-down-icon" />
              </template>

              <ASelectOption
                v-for="item of fromCrypto.list"
                :key="item.code"
                :value="item.code"
              >
                <div class="flex items-center">
                  <i class="text-[20px] mr-[5px]" :class="item.icon" />
                  <span class="lt-mobilel:text-[14px]">{{ item.symbol }}</span>
                </div>
              </ASelectOption>
            </ASelect>
          </div>
        </div>
      </div>
      <div v-if="fromCrypto.info.shortcut.length" class="shortcut-select">
        <div
          v-for="(value, key) of fromCrypto.info.shortcut"
          :key="key"
          class="shortcut-select-value"
          data-test-id="qeds"
          @click="shortcutInput(value)"
        >
          {{ value }}
        </div>
      </div>
      <i
        class="i-local:reverse-currency?mask switch-currency-button"
        data-test-id="fii2"
        @click="switchCrypto"
      />

      <div class="to-currency">
        <div class="balance">
          <span class="label">{{ $t("wZutznikQLic6i7sOz0J5") }}</span>
          <div class="balance-info">
            <span>{{ $t("ggM-EPn4zAWeodigP9_8M") }} </span>

            <ASpin :spinning="isPending">
              <span class="balance-info-number">{{
                toCrypto.info.balance
              }}</span>
            </ASpin>
          </div>
        </div>
        <div class="input">
          <AFormItem v-bind="swapForm.formItemProps.toCrypto">
            <AInputNumber
              v-model:value="(toCrypto.value as number)"
              v-bind="inputMoney"
              :bordered="false"
              :controls="false"
              :placeholder="$t('Z3dE8F8H0GPEYq_juWdf5')"
              :precision="6"
              :min="0"
              :max="toCrypto.info.balance"
              :disabled="true"
            >
              <template #addonAfter>
                <i
                  v-if="!!toCrypto.value"
                  class="i-ant-design:close-circle-outlined input-clear"
                  data-test-id="t1lj"
                  @click="clearValue"
                />
              </template>
            </AInputNumber>
          </AFormItem>
          <div class="select-currency">
            <ASelect v-model:value="toCrypto.code" popupClassName="ant-cover__basic-select-dropdown">
              <template #suffixIcon>
                <i
                  class="i-ant-design:down-outlined select-down-icon"
                />
              </template>

              <ASelectOption
                v-for="item of toCrypto.list"
                :key="item.code"
                :value="item.code"
              >
                <div class="flex items-center">
                  <i class="text-[20px] mr-[5px]" :class="item.icon" />
                  <span class="lt-mobilel:text-[14px]">{{ item.symbol }}</span>
                </div>
              </ASelectOption>
            </ASelect>
          </div>
        </div>
      </div>

      <Transition name="show-address" mode="out-in">
        <template v-if="!receiveAddress.custom">
          <div class="add-address">
            <div
              class="add-address-content"
              data-test-id="prtg"
              @click="receiveAddress.custom = true"
            >
              <i class="i-ant-design:plus-circle-outlined" />
              <span>{{ $t("IAaS-Yi3QV_VZykd97xSt") }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="pay-info">
            <div class="label">
              <span>{{ $t("OGo0ZWBvZ_RXU-OGCJrLt") }}</span>
              <div class="unaddress" data-test-id="2sra" @click="receiveAddress.custom = false">
                <i class="i-ant-design:minus-circle-outlined" />
                <span>{{ $t("EhNh9NR2l0HIFJvs9i7xq") }}</span>
              </div>
            </div>
            <div class="address">
              <AFormItem
                class="address-form-item"
                v-bind="swapForm.formItemProps.receiveAddress"
              >
                <AInput
                  v-model:value="swapForm.formState.receiveAddress"
                  :bordered="false"
                  :placeholder="$t('zuSWHwZcHrG8_0n1I3vpr')"
                  autocomplete="off"
                  data-test-id="qLD8"
                >
                  <template #addonAfter>
                    <i
                      v-if="!!swapForm.formState.receiveAddress"
                      class="i-ant-design:close-circle-outlined address-input-clear"
                      data-test-id="fomj"
                      @click="swapForm.formState.receiveAddress = ''"
                    />
                  </template>
                </AInput>
              </AFormItem>
            </div>
          </div>
        </template>
      </Transition>

      <AButton
        v-if="account.address"
        ref="target"
        class="w-[100%] comfirm-btn"
        data-test-id="792z"
        @click="submit"
      >
        {{ $t("oQJ8d5Rll9Vyu5-Jmb7Gg") }}
      </AButton>

      <AButton
        v-else
        class="w-[100%] comfirm-btn"
        data-test-id="ejny"
        @click="globalModal.ConnectWallet?.open()"
      >
        {{ $t("2QEpLv0zhWEMcJ7HK3XD4") }}
      </AButton>

      <ConfirmPayInfo />
    </div>
  </AForm>
</template>

<style scoped lang="scss">
@media bp-lt-tabletl {
  .dapp-pay {
    padding: 0 16px;

    .wallet-address {
      --uno: 'lt-mobilel:px-[15px] lt-mobilel:py-[10px] lt-mobilel:text-xs';

      & > i {
        --uno: 'w-[16px] !mr-[5px]';
      }
    }

    .add-address {
      & > .add-address-content {
        --uno: 'text-base';
      }
    }

    .pay-info {
      .label {
        --uno: ' text-base';

        .unaddress {
          --uno: 'text-base';
        }
      }

      .address {
        --uno: 'text-[#121c41] text-base mt-[15px]';
      }
    }

    .submit {
      --uno: 'px-[0] py-[20px] rounded-[6px]';
    }
  }
}

:deep(.ant-select-selector){
  height: 40px !important;
}

.dapp-pay {
  --uno: 'mt-[20px]';

  .switch-currency-button {
    --uno: 'w-[30px] h-[30px] mx-[10px] my-[20px] text-[#266ef1] cursor-pointer';
  }

  .wallet-address {
    --uno: 'bg-[--bg-one] text-white rounded-[10px] p-[15px] mb-[20px] flex items-center';
  }

  .add-address {
    --uno: 'mx-[0] my-[30px] flex justify-end items-center';

    & > .add-address-content {
      --uno: 'text-[#26a17b] cursor-pointer flex items-center';

      & > :nth-child(1) {
        --uno: 'mr-[5px]';
      }
    }
  }

  .from-currency {
    --uno: 'bg-[--bg-one] rounded-[10px] lt-tablet:p-15px';

    padding: 5px;
    font-size: 16px;

    & > * {
      --uno: 'lt-tablet:p-0';
    }

    .input {
      display: flex;
      align-items: center;

      :deep(.ant-form-item) {
        flex: 1 0 0;
        margin-bottom: 0 !important;

        input {
          font-size: 22px;
        }
      }
    }

    .balance {
      --uno: 'lt-tablet:p-0 lt-tablet:pb-15px lt-tablet:text-[#8098bf] lt-tablet:text-14px';

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      font-size: 16px;
      color: white;

      & .balance-info {
        --uno: 'lt-tablet:text-12px';

        display: flex;
        align-items: center;

        .ant-spin-blur::after {
          opacity: 0;
        }

        & .balance-info-number {
          margin: 0 5px;
          font-weight: bold;
        }
      }
    }

  }

  .input {
    --uno: 'pt-0 pr-[15px] pb-[5px] pl-[5px] lt-mobilel:p-0';

    .select-currency {
      .currency-item {
        --uno: 'flex items-center text-[white] lt-mobilel:text-14px';
      }

      .select-down-icon {
        color: #c8d0df;
      }
    }

    .input-clear {
      color: #9498a1;
      cursor: pointer;
    }
  }

  .shortcut-select {
    --uno: 'lt-mobilel:pl-[0] lt-mobilel:pr-[0] lt-mobilel:pt-[10px]';

    display: flex;
    justify-content: flex-start;
    padding-top: 15px;

    & > * {
      --uno: 'lt-mobilel:text-[#6f8eca] lt-mobilel:px-[10px] lt-mobilel:py-[5px] lt-mobilel:px-17px lt-mobilel:border-rd-8px text-13px';

      padding: 6px 30px;
      margin-right: 8px;
      font-size: 14px;
      color: var(--secondary-text);
      cursor: pointer;
      border: 1.5px solid rgb(128 152 191 / 50%);
      border-radius: 8px;
      transition: all 0.5s ease-in-out;;
    }

    & > *:hover {
      --uno: 'border-[#266ef1] text-[#266ef1]';
    }
  }

  .to-currency {
    --uno: 'lt-tablet:p-15px';

    padding: 5px;
    background-color: var(--bg-one);
    border-radius: 10px;

    & > * {
      --uno: 'lt-tablet:p-0';
    }

    .input {
      display: flex;
      align-items: center;

      :deep(.ant-form-item) {
        flex: 1 0 0;
        margin-bottom: 0 !important;

        input {
          --uno: 'lt-tablet:text-20px';

          font-size: 22px;
        }
      }
    }

    .balance {
      --uno: 'lt-tablet:p-0 pb-15px text-[#8098bf] text-16px';

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      font-size: 16px;
      color: white;

      & .balance-info {
        --uno: 'lt-tablet:text-12px';

        display: flex;
        align-items: center;

        .ant-spin-blur::after {
          opacity: 0;
        }

        & .balance-info-number {
          margin: 0 5px;
          font-weight: bold;
        }
      }
    }

    .shortcut-select {
      --uno: 'lt-tablet:px-0 lt-tablet:pt-15px lt-tablet:pb-0';

      display: flex;
      justify-content: flex-start;
      padding: 15px;

      & > * {
        --uno: 'lt-tablet:px-17px lt-tablet:py-7px lt-tablet:border-[1.5px_solid_#6f8eca] lt-tablet:text-[#6f8eca] lt-tablet:text-13px';

        padding: 0 15px;
        margin-right: 8px;
        font-size: 12px;
        color: #26a17b;
        cursor: pointer;
        border: 1px solid #26a17b;
        border-radius: 3px;
        transition: all 0.5s ease-in-out;

      }

      & > *:hover {
        color: white;
        background-color: #26a17b;

        @media bp-lt-tablet {
          background-color: #266ef1;
        }
      }
    }
  }

  .pay-info {
    --uno: 'bg-[#13192e] rounded-[6px] mx-[0] my-[20px] px-[20px] pt-[15px]';

    .label {
      --uno: 'text-[white] flex justify-between items-center text-16px';

      .unaddress {
        --uno: '[transition:all_0.5s] opacity-100 text-[#26a17b] cursor-pointer flex items-center';

        & > :nth-child(1) {
          --uno: 'mr-[5px]';
        }
      }
    }

    :deep(.address) {
      --uno: 'text-[#9cb8ff] mt-[10px]';

      .address-form-item {
        input {
          margin-top: 0;
        }
      }

      & > input {
        --uno: 'pl-[0] pr-[0] py-[15px] focus-visible:border-none focus-visible:bg-none';
      }
    }
  }

  .show-address-enter-active,
  .show-address-leave-active {
    --uno: '[transition:opacity_0.3s_ease]';
  }

  .show-address-enter-from,
  .show-address-leave-to {
    --uno: 'opacity-0';
  }
}

// 修改默认输入框样式
:deep(.ant-input-number) {
  color: var(--text-primary-color);
  background-color: var(--bg-lesser-color);

  input::placeholder{
    font-size: 16px;
    color: #bfbfbf;
    border-radius: 0;
  }
}

:deep(.ant-input) {
  color: var(--text-primary-color);
  background-color: var(--bg-lesser-color);

  :first-child {
    font-size: 22px !important;
    background: unset;
    border-radius: 0 !important;
  }

  &::placeholder{
    font-size: 16px;
    color: #bfbfbf;
  }
}
</style>
