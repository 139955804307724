import { createInjectionState } from '@vueuse/core'
import { useCurrencyQuerier } from './useCurrencyQuerier'
import type { ICurrencyInfo } from './useCurrencyQuerier'

// const { config } = useAppState();
export const [useProvideSwap, useSwap] = createInjectionState(() => {
  const { configQuerier } = useAppState()
  const cryptoList = useCurrencyQuerier()
  const fromCrypto = useCrypto(cryptoList, 'tron-usdt')
  const toCrypto = useCrypto(cryptoList, 'tron-trx')
  const rate = computed(() => fromCrypto.info.to[toCrypto.code] ?? 1)

  // const priceInTrx = computed(() => {
  //   return Number(config.value?.base.usdt_price_in_trx?.toFixed(6));
  // });
  const disable = computed(() => configQuerier.isFetching.value)
  const toValue = computed({
    get() {
      const v = fromCrypto.value
      return typeof v !== 'number' ? null : toDecimalPlaces(v * unref(rate), 6)
    },
    set(v) {
      fromCrypto.value = typeof v !== 'number' ? null : toDecimalPlaces(v / unref(rate), 6)
    },
  })

  const switchCrypto = () => {
    // 币种和价值互换
    // const _toValue = unref(toValue);
    // [fromCrypto.code, toCrypto.code] = [toCrypto.code, fromCrypto.code];
    // setTimeout(() => (fromCrypto.value = _toValue));

    // 仅币种互换
    [fromCrypto.code, toCrypto.code] = [toCrypto.code, fromCrypto.code]
  }

  const clearValue = () => {
    fromCrypto.value = null
  }

  const minExchangeAmount = computed(() =>
    fromCrypto.code === 'USDT' ? toCrypto.info.minValue : fromCrypto.info.minValue,
  )

  const UexchangeTRate = computed(() => {
    // return priceInTrx.value! - priceInTrx.value! * config.value!.exchange.exchange_profit_ratio;
    return 1
  })

  const TexchangeURate = computed(() => {
    // return priceInTrx.value! + priceInTrx.value! * config.value!.exchange.exchange_profit_ratio;
    return 1
  })

  return {
    fromCrypto,
    toCrypto: reactive({ ...toRefs(toCrypto), value: toValue }),
    switchCrypto,
    disable,
    clearValue,
    minExchangeAmount,
    UexchangeTRate,
    TexchangeURate,
  }
})

function useCrypto(cryptoList: ICurrencyInfo[], defaultCode: string) {
  const cryptoCode = ref(defaultCode)

  const cryptoInfo = computed(() => {
    const _code = unref(cryptoCode)
    return cryptoList.find(item => item.code === _code)!
  })
  const cryptoValue = ref<number | null>(null)

  return reactive({
    list: cryptoList,
    code: cryptoCode,
    info: cryptoInfo,
    value: cryptoValue,
  })
}
